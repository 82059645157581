import React, { useState } from 'react';
import { TYPE_CONSTANT } from '../../../constant/Web_constant';
import { General_text } from '../../../constant/Translation/General';
import moment from 'moment';
import Alert from '../../Shared/PopUp/Alert';
import { cn } from '../../../utils/classNames';
import { Add_product_text } from '../../../constant/Translation/AddProduct_text';

const PricingRuleForm = ({
  setRuleObject,
  ruleObject,
  setRule,
  schedulesArray,
  variantsArray,
}) => {
  const listing_configs = TYPE_CONSTANT.LISTINGS_CONFIGS;
  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('error');

  // Save time
  const SaveRule = () => {
    if (ruleObject.title === null) {
      setOpenAlert(true);
      setMessage(`${Add_product_text.addpricingrule.title_alert}`);
      return false;
    }
    if (ruleObject.description === null) {
      setOpenAlert(true);
      setMessage(`${Add_product_text.addpricingrule.desc_alert}`);
      return false;
    }
    if (ruleObject.list_price === null) {
      setOpenAlert(true);
      setMessage(`${Add_product_text.addpricingrule.list_price_alert}`);
      return false;
    }

    setRule();
  };

  // Popup close
  const closePopUP = () => {
    setShowError(false);
    setError_message('');
  };

  return (
    <>
      {' '}
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[400px] mx-auto mt-5 `}
        type={'error'}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          setMessage('');
        }}
      >
        <p>{message}</p>
      </Alert>{' '}
      <div className="w-full bg-white shadow-c-sm rounded-card  p-5  ">
        <h3 className=" font-semibold text-primary text-xl mb-4">
          {ruleObject?.id ? 'Edit Purchase Options' : 'Purchase Options'}
        </h3>
        <div className="grid grid-cols-1 gap-6">
          {/* Title */}
          <label className="block">
            <span className="text-gray-700  text-lg  after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px]  ">
              {Add_product_text.addpricingrule.title}
            </span>
            <input
              type="text"
              className="
                    mt-0
                    block
                    w-full
                    px-0.5
                 border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              value={ruleObject?.title}
              onChange={(e) =>
                setRuleObject({
                  ...ruleObject,
                  title: e.target.value,
                })
              }
            />
          </label>
          {/* Desc */}
          <label className="block">
            <span className="text-gray-700  text-lg after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px]  ">
              {Add_product_text.addpricingrule.description}
            </span>
            <textarea
              className="
                    mt-0
                    block
                    w-full
                    px-0.5
                 border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              value={ruleObject?.description}
              onChange={(e) =>
                setRuleObject({
                  ...ruleObject,
                  description: e.target.value,
                })
              }
            />
          </label>
          {/* select Variant */}
          {variantsArray?.length > 0 && (
            <label className="block relative">
              <span className="text-gray-700  text-lg ">
                {Add_product_text.addpricingrule.select_variant}
              </span>

              <select
                className="
                   mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  
                  "
                onChange={(e) =>
                  setRuleObject({
                    ...ruleObject,
                    listing_variant_id: e.target.value,
                  })
                }
              >
                <option disabled selected hidden>
                  {Add_product_text.addpricingrule.select_variant}
                </option>
                {variantsArray?.map((item, index) => (
                  <option
                    selected={ruleObject?.listing_variant_id === item.id}
                    key={item.id}
                    value={item.id}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </label>
          )}
          {/* select schedule */}
          {schedulesArray?.length > 0 && (
            <label className="block relative">
              <span className="text-gray-700  text-lg">
                {Add_product_text.addpricingrule.select_schedule}
              </span>

              <select
                className="
                   mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  
                  "
                onChange={(e) =>
                  setRuleObject({
                    ...ruleObject,
                    listing_schedule_id: e.target.value,
                  })
                }
              >
                <option disabled selected hidden>
                  {Add_product_text.addpricingrule.select_schedule}
                </option>
                {schedulesArray?.map((item, index) => (
                  <option
                    selected={ruleObject?.listing_schedule_id === item.id}
                    key={index}
                    value={item?.id ? item?.id : index}
                  >
                    {moment(item.start_date).format('dddd, MMM  YY')}
                  </option>
                ))}
              </select>
            </label>
          )}

          {/* Price */}
          <label className="block">
            <span className="text-gray-700  text-lg after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px]  ">
              {Add_product_text.addpricingrule.price}
            </span>
            <input
              type="number"
              className="
                    mt-0
                    block
                    w-full
                    px-0.5
                 border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
              value={ruleObject.list_price}
              onChange={(e) =>
                setRuleObject({
                  ...ruleObject,
                  list_price: e.target.value,
                })
              }
            />
          </label>

          <div className="grid grid-cols-[63%,35%] gap-2">
            {/* Bundle */}

            <label className="block">
              <span className="text-gray-700  text-lg after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[10px]  flex items-center justify-start gap-1.5 ">
                {Add_product_text.addpricingrule.purchase_options}
                <button className="tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6  "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  <span className="top_hover text-primary cursor-text">
                    {Add_product_text.addpricingrule.purchase_options_info1}{' '}
                    <br />
                    {Add_product_text.addpricingrule.purchase_options_info2}
                    <br />
                    {Add_product_text.addpricingrule.purchase_options_info3}
                  </span>
                </button>
              </span>

              <select
                className="
                   mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  
                  "
                onChange={(e) =>
                  setRuleObject({
                    ...ruleObject,
                    rule_type: e.target.value,
                  })
                }
              >
                <option selected={ruleObject.rule_type === 1} value={1}>
                  {Add_product_text.addpricingrule.flexible}
                </option>
                <option selected={ruleObject.rule_type === 2} value={2}>
                  {Add_product_text.addpricingrule.bulk}
                </option>{' '}
                <option selected={ruleObject.rule_type === 3} value={3}>
                  {Add_product_text.addpricingrule.batch}
                </option>
              </select>
            </label>

            {/* Rule Value */}
            <label className="block">
              <span className="text-gray-700  text-lg  flex items-center justify-start gap-1.5 ">
                {Add_product_text.addpricingrule.quantity}
              </span>
              <input
                type="number"
                className={cn(
                  ' mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700 focus:ring-0 focus:border-primary ',
                  ruleObject?.rule_type == 1 && ' text-gray-300'
                )}
                placeholder="0"
                disabled={ruleObject?.rule_type == 1}
                value={ruleObject.rule_value}
                onChange={(e) => {
                  setRuleObject({
                    ...ruleObject,
                    rule_value: e.target.value,
                    min_quantity: e.target.value,
                  });
                }}
              />
            </label>
          </div>

          {/* Min */}
          <label className="block">
            <span className="text-gray-700  text-lg">
              {Add_product_text.addpricingrule.min_quantity}
            </span>
            <input
              type="number"
              className={cn(
                ' mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 transition  duration-700 focus:ring-0 focus:border-primary ',
                ruleObject?.rule_type == 3 && ' text-gray-300'
              )}
              onChange={(e) =>
                setRuleObject({
                  ...ruleObject,
                  min_quantity: e.target.value,
                })
              }
              disabled={ruleObject?.rule_type == 3}
              value={ruleObject.min_quantity}
            />
          </label>
        </div>

        {/* Save Button */}
        <div className=" flex justify-center mt-7">
          <button className="btn-primary-outline" onClick={() => SaveRule()}>
            {General_text.general.save}
          </button>
        </div>
      </div>
    </>
  );
};
export default PricingRuleForm;
